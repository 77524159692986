










































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  getPayConfigList,
  PostPayConfig,
  getServiceList,
  depService,
  delService,
  Service,
  getGuidList,
  postGuidList,
  putGuidList,
} from "@/serve/payConfiguration/payConfiguration";
import { uploadImg } from "@/serve/medicalAppointment/institutionManage";
import {
  uploadImgs,
  getImgs,
  submitImgs,
  removeImgs,
} from "@/serve/home/images";
import { ElForm } from "element-ui/types/form";
@Component({
  name: "payConfiguration",
})
export default class PayConfiguration extends Vue {
  public form = {
    size: 10,
    current: 1,
  };
  public formData = {
    type: "微信",
    remark: "",
    value: "",
    status: false,
  };
  private guideRule = {
    name: [{ required: true, message: "请填写标签名称", trigger: "blur" }],
  };
  public rules = {
    // type: [{ required: true, message: '请填写type值', trigger: 'blur' }],
    value: [{ required: true, message: "请填写value值", trigger: "blur" }],
    status: [{ required: true, message: "请选择是否启用", trigger: "blur" }],
    remark: [],
  };
  public imgDesp = "";
  public checkSign = "";
  public typeInter = "";
  public currentPage4 = 1;
  public tableData = [];
  public dialogFormVisible = false;
  public activeNames = ["2", "4"];
  public dialogImageUrl = {};
  public dialogVisible = false;
  public introIdx = 0;
  private intro = false;
  public dialogVisibleCut = false;
  public disabled = false;
  public fileList = [];
  public limit = 4;
  public curFile: any;
  public deletType = false;
  public options = {
    autoCrop: true, //默认生成截图框
    fixedBox: true, //固定截图框大小
    canMoveBox: true, //截图框不能拖动
    autoCropWidth: 620, //截图框宽度
    autoCropHeight: 350, //截图框高度
    centerBox: false, //截图框被限制在图片里面
    fixed: true, // 是否开启截图框宽高固定比例
    full: true, // 是否输出原图比例的截图
    original: false, // 上传图片按照原始比例渲染
    infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
    // full: true,
  };
  private serviceData: {}[] = [];
  // public previews = {}; //实时预览图数据
  public attach = {
    customaryUrl: "", //原图片路径
  };
  public async created() {
    this.getList();
    this.getImgs();
    // await this.getServiceList();
    this.fetchGuide();
  }
  public toolbar = [
    ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
    ["blockquote", "code-block"], //引用，代码块
    [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
    [{ list: "ordered" }, { list: "bullet" }], //列表
    [{ script: "sub" }, { script: "super" }], // 上下标
    [{ indent: "-1" }, { indent: "+1" }], // 缩进
    [{ direction: "rtl" }], // 文本方向
    [{ size: ["small", false, "large", "huge"] }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
    [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
    [{ font: [] }], //字体
    [{ align: [] }], //对齐方式
    ["clean"], //清除字体样式
    ["image", "video"], //上传图片、上传视频
  ];
  public editorOption = {
    placeholder: "请在这里输入",
    modules: {
      toolbar: {
        container: this.toolbar,
        handlers: {
          image: (value: any) => {
            if (value) {
              (document.querySelector(
                ".avatar-uploader2 input"
              ) as HTMLElement).click();
            }
          },
        },
      },
    },
  };
  public async getList() {
    const res = await getPayConfigList(this.form);
    this.formData = res.data.records[0];
  }
  private async getServiceList() {
    const res = await getServiceList();
    if (res.code === 200) {
      const data = res.data;
      let count = 10 - data.length;
      this.serviceData = data || [];
      while (count-- > 0) {
        this.serviceData.push({
          name: "",
          age: "",
          zmId: "",
          phone: "",
          sex: "",
        });
      }
    }
  }
  /**新增或修改客服 */
  private async depService(row: Service<string> | any) {
    try {
      Object.keys(row).forEach((key: string) => {
        if (row[key] === "") {
          console.log("key :>> ", key);
          this.$message({
            type: "warning",
            message: "单行医生信息必须填写完整",
          });
          throw "跳出";
        }
      });
      const { code } = await depService(row);
      if (code === 200) {
        this.$message({
          type: "success",
          message: "保存成功!",
        });
        this.getServiceList();
      }
    } catch (error) {
      //
    }
  }
  /**删除客服 */
  private async delService(row: Service<string>, index: number) {
    const { code } = await delService(row.id as number);
    if (code === 200) {
      this.$message({
        type: "success",
        message: "删除成功!",
      });
      this.$set(this.serviceData, index, {
        name: "",
        age: "",
        zmId: "",
        phone: "",
        sex: "",
      });
    }
  }
  //赋值给表单img字段
  public async handleAvatarSuccess(
    res: { file: File },
    index: number,
    row: { avatar: string }
  ) {
    const imgs = new FormData();
    imgs.append("file", res.file);
    const file = await uploadImg(imgs);
    row.avatar = file.data;
  }
  //限制上传格式和大小
  public beforeAvatarUpload(file: { type: string; size: number }) {
    const isJPG = file.type === "image/jpeg";
    console.log("file.type :>> ", file.type);
    const isPNG = file.type === "image/png";
    const isPG = isJPG || isPNG;
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isPG) {
      this.$message.error("上传头像图片只能是 JPG/PNG格式!");
    }
    if (!isLt2M) {
      this.$message.error("上传头像图片大小不能超过2MB!");
    }
    return isPG && isLt2M;
  }
  // 重置按钮
  public hanlderCancel() {
    // (this.$refs['formData'] as ElForm).resetFields();
    this.formData.value = "";
    this.formData.remark = "";
    this.formData.status = true;
  }
  // 保存按钮
  public async handlerSubmit(): Promise<void> {
    (this.$refs.formData as ElForm).validate(async (valid: any) => {
      if (valid) {
        const res = await PostPayConfig(this.formData);
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.getList();
        }
      }
    });
  }
  public handleChange(val: any) {
    console.log(val);
  }
  //获取图片数据
  public async getImgs() {
    const res = await getImgs();
    this.fileList = res.data.map((item: any) => {
      return {
        url: item.img,
        id: item.id,
        description: item.description,
      };
    });
    this.$forceUpdate();
    if (this.fileList.length >= 4)
      (document.getElementsByClassName("el-upload")[0] as any).style.display =
        "none";
    else
      (document.getElementsByClassName("el-upload")[0] as any).style.display =
        "inline-block";
  }
  public update(file: any) {
    console.log(file, "aa");
    this.curFile = file;
    this.dialogVisibleCut = true;
    const reader = new FileReader();
    reader.readAsDataURL(file.raw); // 以dataURL形式读取文件
    reader.onload = (e: any) => {
      const data = e.target.result;
      this.attach.customaryUrl = data;
      console.log(this.dialogVisibleCut, this.attach);
    };
  }
  //删除图片
  public async handleRemove(file: any) {
    const data = {
      ids: file.id,
    };
    (this.$refs["upload"] as any).handleRemove(file);
    if (!this.deletType) {
      const res = await removeImgs(data);
      if (res.success) {
        this.$message({
          message: "图片删除成功",
          type: "success",
        });
        // this.getImgs();
        (document.getElementsByClassName("el-upload")[0] as any).style.display =
          "inline-block";
      }
    }
  }
  //图片预览
  public handlePictureCardPreview(file: { url: string }) {
    this.dialogImageUrl = file;
    this.dialogVisible = true;
  }
  private editItem() {
    console.log();
    const { id, url, description } = this.dialogImageUrl as any;
    const params = {
      img: url,
      id,
      description,
    };
    submitImgs(params).then(() => {
      this.getImgs();
      this.dialogVisible = false;
    });
  }
  //控制弹出层关闭
  public handleClose() {
    this.dialogVisible = false;
    this.dialogVisibleCut = false;
    this.deletType = true;
    this.handleRemove(this.curFile);
    this.deletType = false;
  }
  //实时预览
  // public realTime(data: {}) {
  //   this.previews = data;
  // }

  //确认裁剪图,上传
  public handleCut() {
    this.dialogVisibleCut = false;
    const formData = new FormData();
    (this.$refs["cropper"] as any).getCropBlob(async (data: any) => {
      // const img = window.URL.createObjectURL(data);
      data = new File([data], "1.jpg");
      // const fileList = [];
      // fileList.push(data);
      // fileList.forEach((item) => formData.append('files', item));
      formData.append("files", data);
      const res = await uploadImgs(formData);
      const imgs = {
        id: 0,
        img: res.data[0],
        description: this.imgDesp,
      };
      const resurl = await submitImgs(imgs);
      if (resurl.success) {
        this.$message({
          message: "图片上传成功",
          type: "success",
        });
        this.imgDesp = "";
        this.fileList = [];
        (document.getElementsByClassName("el-upload")[0] as any).style.display =
          "none";
        setTimeout(() => {
          this.getImgs();
        }, 1000);
      }
    });
  }
  public file!: File;
  public imgUrl = "";
  // 自定义上传
  public async uploadFunc(res: { file: File }) {
    this.file = res.file;
    console.log("this.file", this.file);
    this.imgUrl = URL.createObjectURL(res.file);
    console.log("this.imgUrl", this.imgUrl);
    const imgs = new FormData();
    imgs.append("file", this.file);
    const Img = await uploadImg(imgs);
    const quill = (this.$refs.myQuillEditor as any).quill;
    if (Img.code === 200 && Img.data !== null) {
      const length = quill.getSelection().index;
      quill.insertEmbed(length, "image", Img.data);
      quill.setSelection(length + 1);
    } else {
      this.$message.error("图片插入失败");
    }
  }
  private fetchGuide() {
    getGuidList().then((res) => {
      if (res.code === 200) {
        if (res.data.id) {
          this.guide = res.data;
        } else {
          this.guide = {
            id: "",
            address: "",
            items: [],
          };
        }
      }
    });
  }
  private addOne() {
    this.guide.items.push({
      name: "",
      content: "",
    });
  }
  // 体检指南
  private guide = {
    id: "",
    address: "",
    items: [
      {
        content: "",
        name: "",
      },
    ],
  };
  public async submitOne(formName: string) {
    // postProgram;
    (this.$refs[formName] as ElForm).validate(async (valid) => {
      if (valid) {
        this.guide.items[this.tempIndex] = this.tempRow;
        this.dialogFormVisible = false;
        this.submitGuide();
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  private submitGuide() {
    const params: any = this.guide;
    if (params.id) {
      // 修改
      putGuidList(params).then((res: any) => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.fetchGuide();
        }
      });
    } else {
      if (params.id === "") delete params.id;
      postGuidList(params).then((res: any) => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.fetchGuide();
        }
      });
    }
  }
  private tempRow: any = {
    naem: "",
    content: "",
  };
  private tempIndex = 0;
  // 编辑
  private editRow(e: any, index: number) {
    this.tempRow = e;
    this.dialogFormVisible = true;
    this.tempIndex = index;
  }
  //移除一行
  private deleteRow(e: any) {
    this.guide.items = this.guide.items.filter((i: any, index) => index !== e);
    this.submitGuide();
  }
}
